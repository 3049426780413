import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import EventEmitter from 'eventemitter3'
import type { Accessor } from 'solid-js'
import { createResource, onCleanup, onMount } from 'solid-js'
import { isServer } from 'solid-js/web'
import type TypedEventEmitter from 'typed-emitter'

import { useAuth } from '#/auth/ui/AuthProvider'
import { useSubscriptionState } from '#/subscription/state/SubscriptionContext'
import type { Trpc } from '#/trpc/client'
import { trpc } from '#/trpc/client'

import type { AppRouter } from './server'

type KeyOfTypeTest<T, U> = NonNullable<
  {
    [K in keyof T]: [U] extends [T[K]] ? (T[K] extends U ? K : never) : never
  }[keyof T]
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Queries = KeyOfTypeTest<Trpc, { query: any }>
export type QueryInput<Q extends Queries> = inferRouterInputs<AppRouter>[Q]
export type QueryOutput<Q extends Queries> = inferRouterOutputs<AppRouter>[Q]

type QueryArgs<Q extends Queries> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  query: Trpc[Q]['query'] extends Function ? Q : never
  needsAuth?: boolean
  needsSubscription?: boolean
  fetchOnMount?: boolean
  ssr?: boolean
  skip?: Accessor<boolean>
} & (QueryInput<Q> extends void | undefined
  ? { input?: never }
  : { input: QueryInput<Q> | (() => QueryInput<Q>) })

const eventEmitter = new EventEmitter() as TypedEventEmitter<{
  [key in `refetch.${Queries}`]: () => void
}>

const useTrpcQuery = <Q extends Queries>({
  query,
  input,
  needsAuth,
  needsSubscription,
  fetchOnMount,
  ssr,
  skip,
}: QueryArgs<Q>) => {
  const auth = useAuth()

  const inputFunction = (
    typeof input === 'function' ? input : () => input
  ) as () => QueryInput<Q>

  const [resource, actions] = createResource(
    () =>
      [
        inputFunction(),
        skip?.(),
        needsSubscription !== true ||
          useSubscriptionState().hasActiveSubscription,
      ] as const,
    async ([
      input,
      skip,
      hasActiveSubscriptionOrDoesntNeedSubscription,
    ]): Promise<QueryOutput<Q> | undefined> => {
      if (isServer && (!ssr || needsAuth)) return undefined
      if (skip) return undefined
      if (needsAuth !== false) await auth.waitForAuthentication()
      if (!hasActiveSubscriptionOrDoesntNeedSubscription) return

      // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
      return (trpc[query].query as Function)(input)
    },
    { ssrLoadFrom: 'server', deferStream: true },
  )

  onMount(() => {
    if (fetchOnMount !== false) actions.refetch()
    eventEmitter.on(`refetch.${query}`, actions.refetch)
  })

  onCleanup(() => {
    eventEmitter.off(`refetch.${query}`, actions.refetch)
  })

  return [resource, actions] as const
}

const refetchTrpcQuery = (query: Queries) =>
  eventEmitter.emit(`refetch.${query}`)

export { refetchTrpcQuery, useTrpcQuery }
